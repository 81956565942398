import { render, staticRenderFns } from "./FeaturedProject.vue?vue&type=template&id=2d28d9fa&scoped=true&"
import script from "./FeaturedProject.vue?vue&type=script&lang=js&"
export * from "./FeaturedProject.vue?vue&type=script&lang=js&"
import style0 from "./FeaturedProject.vue?vue&type=style&index=0&id=2d28d9fa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d28d9fa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FeaturedSlider: require('/tmp/build_643efac4/components/FeaturedSlider.vue').default,ArtistDialog: require('/tmp/build_643efac4/components/ArtistDialog.vue').default,FeaturedCountdown: require('/tmp/build_643efac4/components/FeaturedCountdown.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCol,VDivider,VList,VListItem,VRow,VSpacer})
