//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import FeaturedCountdown from '@/components/FeaturedCountdown'
import ArtistDialog from '@/components/ArtistDialog'
import FeaturedSlider from '@/components/FeaturedSlider'

export default {
  name: 'FeaturedProject',
  components: {
    FeaturedSlider,
    FeaturedCountdown,
    ArtistDialog,
  },
  props: {
    featuredProject: {
      type: Object,
      default: null,
    },
    featuredArtist: {
      type: Object,
      default: null,
    },
    sliderTokens: {
      type: Array,
      default: null,
    },
    reload: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    countdownDate() {
      const now = moment()
      const startDate = moment(this.featuredProject.start_at)
      const endDate = moment(this.featuredProject.end_at)
      let countdownDate = now
      if (now < startDate) {
        countdownDate = moment(startDate)
      } else if (now > startDate && now < endDate) {
        countdownDate = moment(endDate)
      } else if (now > endDate) {
        countdownDate = now
      }
      if (!countdownDate.isValid()) {
        countdownDate = null
      }
      return countdownDate
    },
    projectStatus() {
      const { statuses } = this.$config
      if (this.featuredProject.status === statuses.scheduled) {
        return 'Starting'
      } else if (
        this.featuredProject.status === statuses.ongoing &&
        this.featuredProject.end_at !== null
      ) {
        return 'Ending'
      } else {
        return null
      }
    },
  },
  created() {
    console.log(this.countdownDate.toISOString(), this.status)
  },
  methods: {
    formatDate(date, format) {
      return moment(date).format(format)
    },
    completed() {
      console.log()
    }
  },
}
